import {
	blackColor,
	graysPalette,
	whiteColor,
	container,
	getFontStyles
} from 'assets/jss/material-kit-pro-react.js';

const textSmall = {
	fontSize: '14px'
};
const textMedium = {
	fontSize: '16px'
};
const textLarge = {
	fontSize: '18px'
};
const titleSmall = {
	fontSize: '22px'
};
const titleMedium = {
	fontSize: '26px'
};
const titleLarge = {
	fontSize: '30px'
};
const titleXLarge = {
	fontSize: '36px'
};

const lineHeightSmall = {
	lineHeight: 1
};
const lineHeightMedium = {
	lineHeight: 1.375
};
const lineHeightLarge = {
	lineHeight: 1.8
};

const bold = {
	fontWeight: 'bold'
};
const black = {
	color: blackColor
};
const gray = {
	color: graysPalette['grayCC']
};
const white = {
	color: whiteColor
};

const actionLink = {
	fontSize: '14px',
	lineHeight: 1.375,
	color: blackColor,
	textDecoration: 'underline',

	'&:hover, &:focus': {
		color: blackColor
	}
};

const classicPageHeaderStyle = (theme) => ({
    container: {
        ...container,
        ...getFontStyles(theme.palette.fontFamily)
    },
    center: {
        textAlign: 'center'
    },
    titleXLarge: {
        ...titleXLarge,
        ...getFontStyles(theme.palette.fontFamily)
    },
    titleLarge: {
        ...titleLarge,
        ...getFontStyles(theme.palette.fontFamily)
    },
    titleMedium: {
        ...titleMedium,
        ...getFontStyles(theme.palette.fontFamily)
    },
    titleSmall: {
        ...titleSmall,
        ...getFontStyles(theme.palette.fontFamily)
    },
    textLarge: {
        ...textLarge,
        ...getFontStyles(theme.palette.fontFamily)
    },
    textMedium: {
        ...textMedium,
        ...getFontStyles(theme.palette.fontFamily)
    },
    textSmall: {
        ...textSmall,
        ...getFontStyles(theme.palette.fontFamily)
    },
    lineHeightSmall: {
        ...lineHeightSmall,
        ...getFontStyles(theme.palette.fontFamily)
    },
    lineHeightMedium: {
        ...lineHeightMedium,
        ...getFontStyles(theme.palette.fontFamily)
    },
    lineHeightLarge: {
        ...lineHeightLarge,
        ...getFontStyles(theme.palette.fontFamily)
    },
    bold: {
        ...bold
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    primary: {
        color: theme.palette.primary.main
    },
    black: {
        ...black
    },
    white: {
        ...white
    },
    gray: {
        ...gray
    },
    spacingTop3: {
        marginTop: theme.spacing(3)
    },
    spacingTop4: {
        marginTop: '20px'
    },
    spacingTop2: {
        marginTop: theme.spacing(2)
    },
    spacingBottom2: {
        marginBottom: theme.spacing(2)
    },
	spacingBottom1: {
		marginBottom: theme.spacing(1)
	},
	spacingBottom0: {
		marginBottom: 12
	},
    actionLink: {
        ...actionLink
    },
    separator: {
        marginLeft: theme.spacing(1) / 2,
        marginRight: theme.spacing(1) / 2,
        ...black,
        fontSize: '16px'
    }
});

export default classicPageHeaderStyle;

export {
	textSmall,
	textMedium,
	textLarge,
	titleSmall,
	titleMedium,
	titleLarge,
	titleXLarge,
	lineHeightSmall,
	lineHeightMedium,
	lineHeightLarge,
	bold,
	black,
	gray,
	white,
	actionLink
};

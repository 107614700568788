/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import 'services/globalConfigurations';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'assets/scss/material-kit-pro-react.scss?v=1.9.0';
// pages for this product

import config from 'react-global-configuration';

import { isBrowserSupported, getEnvironment } from 'services/misc';
import HeartsLoader from 'components/Loaders/HeartsLoader';
const AppRenderer = React.lazy(() => import('AppRenderer'));
const BrowserNotSupportedPage = React.lazy(() =>
	import('views/BrowserNotSupportedPage/BrowserNotSupportedPage')
);

config.setEnvironment(config.get('currentEnvironment'));

const  sentryConfig = config.get('sentryConfig')
if(sentryConfig && sentryConfig.sendErrors){
	Sentry.init({
		ignoreErrors: sentryConfig.ignoreErrors,
		dsn: sentryConfig.DNS,
		release: process.env.REACT_APP_RELEASE ?? 'master',
		environment: getEnvironment(),
		autoSessionTracking: sentryConfig.autoSessionTracking,
		integrations: [
			new Integrations.BrowserTracing(),
		],
		tracesSampleRate: sentryConfig.tracesSampleRate,
		debug: sentryConfig.debug,
		maxBreadcrumbs:sentryConfig.maxBreadcrumbs
	});
}

ReactDOM.render(
	<Suspense fallback={<HeartsLoader />}>
		{isBrowserSupported() && <AppRenderer />}
		{!isBrowserSupported() && <BrowserNotSupportedPage />}
	</Suspense>,
	document.getElementById('root')
);

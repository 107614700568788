import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import styles from 'assets/jss/amateur/components/gridStyle.js';

const useStyles = makeStyles(styles);

export default function GridItem(props) {
	const { children, className, ...rest } = props;
	const classes = useStyles();
	return (
		<Grid item {...rest} className={`${classes.gridItem} ${className}`}>
			{children}
		</Grid>
	);
}

GridItem.defaultProps = {
	className: ''
};

GridItem.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string
};

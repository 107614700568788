//Detect what is the base language based on subdomain

import globalConfig from 'react-global-configuration';
import { langSettingLocalStorageKey } from 'definitions/amateurDevEnvironmentsDefinitions';
import { getLangKeyFromDevEnvironmentIfExists } from 'hooks/useAmateurDevEnvironmentsManager';
import { PREPRODUCTIONENVIRONMENT } from 'definitions/EnvironmentsDefinitions';

const envsWithWww = [PREPRODUCTIONENVIRONMENT];

const prepareUrlForLang = (
	lang,
	availableLanguages = [],
	host = window.location.host,
	config = globalConfig
) => {
	const hasWww = envsWithWww.map((e) => host.includes(e)).reduce((a, e) => a || e);
	let hostWithNoLanguage = hasWww ? host : host.replace('www.', '');
	const parts = hostWithNoLanguage.split('.');
	if (parts.length >= 3 && availableLanguages.includes(parts[0])) {
		hostWithNoLanguage = hostWithNoLanguage.replace(`${parts[0]}.`, '');
	}

	//!config.get("availableLanguages").includes(lang) - > Not included so it's easily understandable in testing that it does not work
	if (lang == config.get('defaultLanguage')) {
		return window.location.protocol + '//' + hostWithNoLanguage + '/';
	} else {
		return window.location.protocol + '//' + lang + '.' + hostWithNoLanguage + '/';
	}
};

const domainLang = () => {
	globalConfig.setEnvironment(globalConfig.get('currentEnvironment'));
	let loadLanguage = globalConfig.get('defaultLanguage');

	const langFromDevEnvironmentManager = getLangKeyFromDevEnvironmentIfExists();
	if (langFromDevEnvironmentManager) {
		return langFromDevEnvironmentManager;
	} else {
		let host = window.location.host;
		let parts = host.split('.');
		if (parts.length >= 3) {
			switch (parts[0]) {
				case 'en': {
					loadLanguage = 'en';
					break;
				}

				case 'fr': {
					loadLanguage = 'fr';
					break;
				}

				case 'es': {
					loadLanguage = 'es';
					break;
				}
			}
		}
	}

	return loadLanguage;
};
const browserLang = () => {
	return navigator.language.slice(0, 2);
};

export { domainLang, browserLang, prepareUrlForLang };

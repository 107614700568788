const langSettingLocalStorageKey = 'langSetting';
const langSettingUrlParamKey = 'lang';
const videoSettingLocalStorageKey = 'videoTechSetting';
const videoSettingUrlParamKey = 'videoTech';
const betaFeaturesSettingUrlParamKey = 'beta';
const betaFeaturesSettingLocalStorageKey = 'betaSetting';
const defaultValueToRemoveSetting = 'default';
export {
	langSettingLocalStorageKey,
	langSettingUrlParamKey,
	videoSettingLocalStorageKey,
	videoSettingUrlParamKey,
	defaultValueToRemoveSetting,
	betaFeaturesSettingLocalStorageKey,
	betaFeaturesSettingUrlParamKey
};

const WEBCAMCATEGORYALL = 'A';
const WEBCAMCATEGORYPRO = 'P';
const WEBCAMCATEGORYTRANS = 'T';
const WEBCAMCATEGORYCOUPLES = 'C';
const WEBCAMCATEGORYMEN = 'M';
const WEBCAMCATEGORYWOMEN = 'W';
const WEBCAMCATEGORYFAVORITED = 'F';

export {
	WEBCAMCATEGORYALL,
	WEBCAMCATEGORYCOUPLES,
	WEBCAMCATEGORYFAVORITED,
	WEBCAMCATEGORYMEN,
	WEBCAMCATEGORYPRO,
	WEBCAMCATEGORYTRANS,
	WEBCAMCATEGORYWOMEN
};

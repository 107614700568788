import React, { useEffect } from 'react';
import { launchCustomAlert } from 'services/misc';
import { useTranslation } from 'react-i18next';
import {
	betaFeaturesSettingLocalStorageKey,
	betaFeaturesSettingUrlParamKey,
	defaultValueToRemoveSetting,
	langSettingLocalStorageKey,
	langSettingUrlParamKey,
	videoSettingLocalStorageKey,
	videoSettingUrlParamKey
} from 'definitions/amateurDevEnvironmentsDefinitions';
const useAmateurDevEnvironmentsManager = () => {
	const { t, i18n } = useTranslation();

	const evaluateCurrentUrlQueryStringAndProcessDevEnvironments = () => {
		const queryString = window.location.search;

		if (!queryString) {
			return;
		}
		const url = new URLSearchParams(queryString);

		const langSetting = url.get(langSettingUrlParamKey);
		const videoSetting = url.get(videoSettingUrlParamKey);
		const betaFeaturesSetting = url.get(betaFeaturesSettingUrlParamKey);

		const newExpireTime = new Date().getTime() + 1 * 60 * 60 * 1000;

		if (betaFeaturesSetting) {
			setTimeout(() => {
				launchCustomAlert(
					'DEVELOPER MODE - BETA FEATURES ENABLED : ' +
						(betaFeaturesSetting !== defaultValueToRemoveSetting &&
						betaFeaturesSetting !== 'no' &&
						betaFeaturesSetting !== 'off' &&
						betaFeaturesSetting !== 'disabled'
							? 'TRUE - TO EXPIRE AFTER 1 HOUR'
							: 'FALSE')
				);
			}, 2000);

			if (
				betaFeaturesSetting !== defaultValueToRemoveSetting &&
				betaFeaturesSetting !== 'no' &&
				betaFeaturesSetting !== 'off' &&
				betaFeaturesSetting !== 'disabled'
			) {
				localStorage.setItem(
					betaFeaturesSettingLocalStorageKey,
					JSON.stringify({
						betaFeaturesSetting: 'on',
						expires: newExpireTime
					})
				);
			} else {
				localStorage.removeItem(betaFeaturesSettingLocalStorageKey);
			}
		}
		if (langSetting) {
			setTimeout(() => {
				launchCustomAlert(
					'DEVELOPER MODE - LANG SETTING:' + langSetting + ' - TO EXPIRE AFTER 1 HOUR'
				);
			}, 2000);

			if (langSetting === defaultValueToRemoveSetting) {
				localStorage.removeItem(langSettingLocalStorageKey);
			} else {
				localStorage.setItem(
					langSettingLocalStorageKey,
					JSON.stringify({
						langSetting: langSetting,
						expires: newExpireTime
					})
				);
			}

			window.location.replace('/');
		}

		if (videoSetting) {
			setTimeout(() => {
				launchCustomAlert(
					'DEVELOPER MODE - VIDEO SETTING:' + videoSetting + ' - TO EXPIRE AFTER 1 HOUR'
				);
			}, 2000);

			if (langSetting === defaultValueToRemoveSetting) {
				localStorage.removeItem(videoSettingLocalStorageKey);
			} else {
				localStorage.setItem(
					videoSettingLocalStorageKey,
					JSON.stringify({
						videoTech: videoSetting,
						expires: newExpireTime
					})
				);
			}
		}

		removeExpiredSettings();
	};

	const removeExpiredSettings = () => {
		checkAndRemoveSettingIfExpired(langSettingLocalStorageKey);
		checkAndRemoveSettingIfExpired(videoSettingLocalStorageKey);
		checkAndRemoveSettingIfExpired(betaFeaturesSettingLocalStorageKey);
	};

	const checkAndRemoveSettingIfExpired = (settingKey) => {
		const currentTime = new Date().getTime();

		const settingUnparsed = localStorage.getItem(settingKey);
		if (settingUnparsed) {
			const isExpired = currentTime >= JSON.parse(settingUnparsed).expires;
			if (isExpired) {
				localStorage.removeItem(settingKey);
				window.location.replace('/');
			}
		}
	};

	return {
		evaluateCurrentUrlQueryStringAndProcessDevEnvironments
	};
};

const getAreBetaFeaturesEnabledFromDevEnvironmentIfExists = () => {
	const settingUnparsed = localStorage.getItem(betaFeaturesSettingLocalStorageKey);

	if (settingUnparsed) {
		return true;
	}
	return false;
};

const getVideoTechFromDevEnvironmentIfExists = () => {
	const settingUnparsed = localStorage.getItem(videoSettingLocalStorageKey);

	if (settingUnparsed) {
		return JSON.parse(settingUnparsed).videoTech;
	}
	return null;
};

const getLangKeyFromDevEnvironmentIfExists = () => {
	const settingUnparsed = localStorage.getItem(langSettingLocalStorageKey);

	if (settingUnparsed) {
		return JSON.parse(settingUnparsed).langSetting;
	}
	return null;
};
export {
	useAmateurDevEnvironmentsManager as default,
	getVideoTechFromDevEnvironmentIfExists,
	getLangKeyFromDevEnvironmentIfExists,
	getAreBetaFeaturesEnabledFromDevEnvironmentIfExists
};

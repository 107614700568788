const NINJAUSERNAME = 'game_user_ninja';
const RATINGUP = 'ratingUp';
const RATINGDOWN = 'ratingDown';
const ACTION_REDIRECT_TYPE = 'actionRedirectType';
const INTERNAL_LINK_REDIRECT_TYPE = 'internalLinkRedirectType';
const EXTERNAL_LINK_REDIRECT_TYPE = 'externalLinkRedirectType';
const REDIRECT_TYPE_OLD = 'REDIRECT_TYPE_OLD';
const REDIRECT_TYPE_NEW = 'REDIRECT_TYPE_NEW';

export {
    NINJAUSERNAME,
    RATINGUP,
    RATINGDOWN,
    ACTION_REDIRECT_TYPE,
    INTERNAL_LINK_REDIRECT_TYPE,
    EXTERNAL_LINK_REDIRECT_TYPE,
    REDIRECT_TYPE_OLD,
    REDIRECT_TYPE_NEW
};

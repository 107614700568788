import React from 'react';

const HeartsLoader = () => {
	return (
		<div className='full-page-loader'>
			<img width='180' src='/img/loader.png' alt='...' />
		</div>
	);
};

export default HeartsLoader;

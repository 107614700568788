const PRODUCTIONENVIRONMENT ='production';
const DEVELENVIRONMENT ='devel';
const PREPRODUCTIONENVIRONMENT='pre';
const PREBETAENVIRONMENT='pre';
const COMUNITYENVIRONMENT='comunity';
const AMATEURHOST = 'www.amateur.tv';
const DEVELHOST = 'panelmoderacioncams.amateur.tv';
const LOCALHOSTHOST = 'localhost';
const PREPRODHOST ='atvbeta.amateur.tv'
const PREBETAPRODHOST ='atvpre.amateur.tv'

export {
    PRODUCTIONENVIRONMENT,
    DEVELENVIRONMENT,
    PREPRODUCTIONENVIRONMENT,
    COMUNITYENVIRONMENT,
    PREBETAENVIRONMENT,
    AMATEURHOST,
    DEVELHOST,
    LOCALHOSTHOST,
    PREPRODHOST,
    PREBETAPRODHOST
};